//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "PageNotFound",
  watch: {
    "$store.state.list.search_input"(val) {
      if (val != null && val != "") {
        this.searchFocused = true;
      } else {
        this.searchFocused = false;
      }
    },
  },
  data: function() {
    return {
      searchFocused: false,
      inputFocus: false,
      showLimitedOffer: true,
      activeBoys: true,
      activeGirls: false,
      activeSearchClass: false,
      scrollPosition: null,
      search: false,
      searchshow: true,
      mobileview: false,
    };
  },
  methods: {
    focusInputSearch(e){
      this.$store.state.list.search_input = e.target.value;
    },
  },
};
