import { render, staticRenderFns } from "./pagenotfound.vue?vue&type=template&id=eb25fe52&scoped=true&"
import script from "./pagenotfound.vue?vue&type=script&lang=js&"
export * from "./pagenotfound.vue?vue&type=script&lang=js&"
import style0 from "./pagenotfound.vue?vue&type=style&index=0&id=eb25fe52&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb25fe52",
  null
  
)

export default component.exports