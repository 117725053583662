//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  import axios from 'axios';
export default {
  name: "PageNotFound",
  watch: {
    "$store.state.list.search_input"(val) {
      if (val != null && val != "") {
        this.searchFocused = true;
      } else {
        this.searchFocused = false;
      }
    },
  },
  props: ['urlkey'],
  mounted() {
     if(this.urlkey.length > 0){
       this.getPrduct();
     }    
  },
  data: function() {
    return {
      Product_list:[],
      searchFocused: false,
      inputFocus: false,
      showLimitedOffer: true,
      activeBoys: true,
      activeGirls: false,
      activeSearchClass: false,
      scrollPosition: null,
      search: false,
      searchshow: true,
      mobileview: false,
    };
  },
  methods: {
    focusInputSearch(e){
      this.$store.state.list.search_input = e.target.value;
    },
    async getPrduct(){      
       this.$store.state.list.page_loader = true;
      await axios.get(this.$store.state.list.api_url + `/pim/pimresponse.php/`, {
                        params: {
                            service: 'category',
                            store: this.$store.state.list.store,
                            url_key: this.urlkey,
                            page:1,
                            count: 8,
                            sort_by: '',
                            sort_dir: 'desc',
                            filter: ''
                        }
                    }).then(response=>{
                        this.$store.state.list.page_loader = false;
                      if(response.data.result.products){
                         this.Product_list = [];
                         this.Product_list = this.Product_list.concat(response.data.result.products);
                     
                      }
                    }).catch(error => {
                            this.$router.push('/pagenotfound')
                            if (error.message === "Network Error") {
                                this.$store.state.list.error_message = 'Oops there seems to be some Network issue, please try again.';
                            }
                      })
     }
  },
  beforeMount(){
   
  }
  
};
