//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios";
// import Slick from 'vue-slick';
// import 'vue-slider-component/theme/default.css';
import ClickOutside from "vue-click-outside";
import error from "~/pages/error";
import PageNotFound from "~/pages/pagenotfound";
// import VueRangeSlider from 'vue-range-component';
// import vueSlider from 'vue-slider-component';
export default {
  name: "Product_list",
  head() {
    return {
      bodyAttrs: {
        class: "w-store list-pages",
      },

      title: this.meta_title,
      link: [
        {
          rel: "prev",
          href: this.prev,
        },
        {
          rel: "next",
          href: this.next,
        },
      ],
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.meta_description,
        },
        {
          hid: "og:title",
          property: "og:title",
          content: this.meta_title,
        },
        {
          hid: "og:url",
          property: "og:url",
          content: this.$store.state.list.base_Url + this.$route.path,
        },
        {
          hid: "og:image",
          property: "og:image",
          content: "https://walkwayshoes.com/images-walk/logo.png",
        },
        {
          hid: "og:image-width",
          property: "og:image-width",
          content: "200",
        },
        { hid: "og:image-height", content: "200" },
        {
          hid: "og:description",
          property: "og:description",
          content: this.meta_description,
        },
        {
          name: "twitter:site",
          content: "@Walkway",
        },
        {
          name: "twitter:title",
          content: this.meta_title,
        },
        {
          name: "twitter:description",
          content: this.meta_description,
        },
        {
          name: "twitter:image",
          content: "https://walkwayshoes.com/images-walk/logo.png",
        },
        {
          name: "twitter:app:country",
          content: "IN",
        },
        {
          name: "twitter:app:name:iphone",
          content: "Walkway",
        },
        {
          name: "twitter:app:url:iphone",
          content: this.$store.state.list.base_Url + this.$route.path,
        },
        {
          name: "twitter:app:name:googleplay",
          content: "Walkway",
        },
        {
          name: "twitter:app:url:googleplay",
          content: this.$store.state.list.base_Url + this.$route.path,
        },
        {
          name: "twitter:app:id:googleplay",
          content: "com.walkway.android",
        },
      ],
      script: [
        {
          json: [
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              name: this.categorie_name,
              url: `https://walkwayshoes.com/${this.categoryURL}`,
              description: 'Footwear for' + ' ' + this.schema_name,
              publisher: {
                "@type": "WebPage",
                name: "Walkway",
              },
            },
            this.$store.state.list.breadCrumbArray &&
            this.$store.state.list.breadCrumbArray.length > 0
              ? {
                  "@context": "http://schema.org",
                  "@type": "BreadcrumbList",
                  itemListElement: this.$store.state.list.breadCrumbArray.map(
                    (item, index) => {
                      return {
                        "@type": "ListItem",
                        position: index + 1,
                        name: item.name,
                        item: item.url,
                      };
                    }
                  ),
                }
              : {},
            this.$store.state.list.topProducts &&
            this.$store.state.list.topProducts.length > 0
              ? {
                  "@context": "https://schema.org",
                  "@type": "ItemList",
                  url: "https://mybiofoot.com/men.html",
                  numberOfItems: this.$store.state.list.topProducts.length,
                  itemListElement: this.$store.state.list.topProducts.map(
                    (product, index) => {
                      return {
                        "@type": "ListItem",
                        position: index + 1,
                        item: {
                          "@type": "Product",
                          name: product.name,
                          description: product.description,
                          url: `https://walkwayshoes.com/product/${product.url_key}`,
                          image: `https://admin.walkwayshoes.com/product/17-14/1080/${product.image}`,
                          brand: {
                            "@type": "Brand",
                            name: product.brand,
                          },
                          offers: {
                            "@type": "Offer",
                            price: product.selling_price,
                            priceCurrency: "INR",
                            availability: product.stock_status,
                          },
                        },
                      };
                    }
                  ),
                }
              : {},
          ],
          type: "application/ld+json",
        },
      ],
    };
  },
  // metaInfo () {
  //     return {
  //             link:[
  //             {
  //                 rel:"canonical",
  //                 href:"https://"+location.host+this.$route.fullPath
  //             }
  //         ]
  //     }
  // },

  props: {
    formatter: null,
    tooltipMerge: null,
    enableCross: null,
    slider: null,
  },
  components: {
    // vueSlider,
    PageNotFound,
    error,
  },
  directives: {
    ClickOutside,
  },
  data: function () {
    return {
      hideMount: true,
      pageUrl: "",
      schema_name: "",
      schema_desc: "",
      schema_image: "",
      blankcategory: "",
      result: "",
      urlkey: [],
      foldContent: "",
      isQuerySort: false,
      moreColors: {},
      showMoreColorsMobile: false,
      slickOptions: {
        lazyLoad: "ondemand",
        slidesToScroll: 1,
        slidesToShow: 1,
        asNavFor: ".product_image_slide",
        arrows: false,
        infinite: true,
        dots: true,
        autoplay: true,
        autoplaySpeed: 1000,
        pauseOnFocus: false,
        pauseOnHover: false,
        pauseOnDotsHover: false,
      },
      // images_array:[
      //     url='https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/11568388/2020/3/5/55c51558-cceb-4e8f-ae71-689d81f218e41583384530038-Libas-Women-Green--Blue-Printed-Kurta-with-Palazzos--Dupatta-1.jpg'
      // ],
      pricerange: {
        value: [],
        options: {
          dotSize: 14,
          width: "auto",
          height: 4,
          contained: false,
          direction: "ltr",
          data: null,
          min: null,
          max: null,
          code: null,
          interval: 1,
          disabled: false,
          clickable: true,
          duration: 0.5,
          adsorb: false,
          lazy: false,
          tooltip: "active",
          tooltipPlacement: "top",
          tooltipFormatter: void 0,
          useKeyboard: false,
          keydownHook: null,
          dragOnClick: false,
          enableCross: true,
          fixed: false,
          minRange: void 0,
          maxRange: void 0,
          order: true,
          marks: false,
          dotOptions: void 0,
          process: true,
          dotStyle: void 0,
          railStyle: void 0,
          processStyle: void 0,
          tooltipStyle: void 0,
          stepStyle: void 0,
          stepActiveStyle: void 0,
          labelStyle: void 0,
          labelActiveStyle: void 0,
        },
      },
      similar_Product: [],
      similarPopup: false,
      wishlist: [],
      meta_title: "",
      description: "",
      meta_description: "",
      meta_keyword: "",
      product_loader: true,
      Product_MoreLoad: true,
      filter_error_msg: "",
      filter_status: true,
      mobilefilterActive: "",
      blankpage: false,
      page: 1,
      pageload: true,
      pslidedisplay: false,
      pimagedisplay: true,
      sameslide: 9999,
      pageload: true,
      pageSize: "",
      service: "category",
      count: 32,
      sort_show: false,
      id_category: this.$route.params.cat_id,
      Product_list: [],
      filters: [],
      filtersAppied: [],
      filterview: [],
      apifilter: "",
      filter_api_url: [],
      colorsActive: [],
      sizesActive: [],
      sort: [
        { code: "price_low", label: "Price: Low to High" },
        { code: "selling_price", label: "Price: High to Low" },
      ],
      select_sort: "sku_rank",
      selected_sort: "Recommended",
      pass_sort: "sku_rank", //"selling_price",
      pass_url_key: this.$route.params.category,
      sort_dir: "desc",
      categorie_name: "",
      current_filter: "",
      Product_count: "",
      get_product_length: "",
      gtm_product_impressions: [],
      mobile_view: false,
      categoryURL: "",
    };
  },

  methods: {
    dataForGA4Events(productData, catName) {
      if (productData.length > 0) {
        this.gtm_product_impressions_GA4 = [];
        this.gtm_product_impressions = [];
        for (let i = 0; i < productData.length; i++) {
          let item_name = productData[i].name;
          let item_id = productData[i].sub_group_id;
          let item_subGId = productData[i].sub_group_id;
          let item_productId = productData[i].sku;
          let price = productData[i].selling_price;
          let item_category = productData[i].category;
          let index = i + 1;
          let list = catName;
          let id = item_productId;
          let name = item_name;
          let position = index;
          let category = item_category;
          this.gtm_product_impressions.push({
            name,
            id,
            price,
            category,
            list,
            position,
          });
          this.gtm_product_impressions_GA4.push({
            item_id,
            item_subGId,
            item_name,
            item_category,
            item_productId,
            price,
            index,
          });
        }
      }
    },

    pdpRedirect() {},
    pageQuery() {
      var productMain = document.querySelector(".product-section-main");
      var productOne = document.querySelector(
        ".product-section-main .product-list .row div.new-class"
      );
      if (
        productMain &&
        productMain.offsetWidth &&
        productOne &&
        productOne.offsetWidth
      ) {
        var rowElement = Math.round(
          productMain.offsetWidth / productOne.offsetWidth
        );
        let height = (32 / rowElement) * productOne.offsetHeight;
        this.scrollPush(height);
      }
    },
    scrollPush(param = 3650) {
      if (Math.floor(window.scrollY / param) > 0) {
        this.$router.push({
          query: {
            ...this.$route.query,
            page: Math.floor(window.scrollY / param),
          },
        });
      } else {
        this.$router.push({
          query: { ...this.$route.query, page: undefined },
        });
      }
    },
    getMoreColorsMobileProducts(article) {
      this.showMoreColorsMobile = true;
      this.moreColors = article;
    },
    similarProduct(url) {
      this.$store.state.list.page_loader = true;
      axios
        .get(this.$store.state.list.api_url + `/pim/pimresponse.php/`, {
          params: {
            service: "product",
            store: this.$store.state.list.store,
            url_key: url,
          },
        })
        .then((response) => {
          this.$store.state.list.page_loader = false;
          if (response.data.response.success === 1) {
            this.similarPopup = true;
            this.similar_Product = response.data.result.similar_product_list;
          } else {
            this.$toast.error(response.data.response.error_message, {
              position: "top",
              duration: 4000,
            });
          }
        })
        .catch((error) => {
          this.$store.state.list.page_loader = false;
          if (error.message === "Network Error") {
            this.$toast.error(
              "Oops there seems to be some Network issue, please try again.",
              {
                position: "top",
                duration: 4000,
              }
            );
          }
        });
      //this.similar_Product = this.Product_list[index].similar_product_list;
      document.body.style.overflow = "hidden";
    },
    similarPopupclose() {
      document.body.style.overflow = "auto";
      this.similarPopup = false;
    },
    stopPropagation(event) {
      event.stopPropagation();
    },
    add_to_cart(index) {
      if (this.$store.state.list.cart_product.length != 0) {
        var found = false;
        for (let i = 0; i < this.$store.state.list.cart_product.length; i++) {
          if (
            this.$store.state.list.cart_product[i].variant_id ==
            this.Product_list[index].variation[0].id_product
          ) {
            if (
              this.$store.state.list.cart_product[i].max_qty <=
              this.$store.state.list.cart_product[i].qty
            ) {
              this.$toast.error(
                "Available quantity you have already added to your cart",
                {
                  position: "top",
                }
              );
              found = true;
              break;
            }
          }
        }
        if (found === false) {
          this.$store.state.list.cart_id = $cookies.get(
            window.location.hostname.substring(10, 4) + "_cart"
          );
          this.$store.state.list.page_loader = true;
          var form = new FormData();
          form.append(
            "product_id",
            this.Product_list[index].variation[0].id_product
          );
          form.append("product_parent_id", this.Product_list[index].id_product);
          form.append(
            "product_options",
            this.Product_list[index].variation[0].configrable_atribute_value
          );
          form.append("name", this.Product_list[index].name);
          form.append("sku", this.Product_list[index].variation[0].sku);
          form.append("master_sku", this.Product_list[index].sku);
          form.append("price", this.Product_list[index].price);
          form.append("qty_ordered", "1");
          form.append("final_price", this.Product_list[index].selling_price);
          form.append("store", this.$store.state.list.store);
          if (
            this.$store.state.list.cart_id != null &&
            this.$store.state.list.cart_id != ""
          ) {
            form.append("cart_id", this.$store.state.list.cart_id);
          }
          if (
            this.$store.state.list.customer_id != null &&
            this.$store.state.list.customer_id != ""
          ) {
            form.append("customer_id", this.$store.state.list.customer_id);
          }
          let config = { headers: { "Content-Type": "multipart/form-data" } };
          axios
            .post(
              this.$store.state.list.cart_api_url + "/product/add-product",
              form,
              config
            )
            .then((response) => {
              if (response.data.success === true) {
                this.$toast.success(response.data.message, {
                  position: "top",
                  duration: 4000,
                });
                this.$store.state.list.error_message = "";
                this.$store.state.list.page_loader = false;
                this.$store.state.list.cart_product =
                  response.data.data.products;
                this.$store.state.list.cart_total =
                  response.data.data.grand_total;
                this.$store.state.list.cart_subtotal =
                  response.data.data.order_subtotal;
                this.$store.state.list.discount_code =
                  response.data.data.discount_code;
                this.$store.state.list.discount_amount =
                  response.data.data.discount_amount;
                this.$store.state.list.address = response.data.data.address;
                if (this.$store.state.list.address.length != 0) {
                  for (
                    let i = 0;
                    i < this.$store.state.list.address.length;
                    i++
                  ) {
                    if (i == 0) {
                      this.$store.state.list.address_id =
                        this.$store.state.list.address[i].id * 1;
                      this.$store.state.list.select_address_no = i;
                    }
                  }
                }
                $cookies.set(
                  window.location.hostname.substring(10, 4) + "_cart",
                  response.data.data.cart_id
                );
                this.$store.state.list.cart_id = response.data.data.cart_id;
                this.$store.state.list.thankyou_cart =
                  response.data.data.cart_id;
                this.$store.state.list.minicart = false;
              } else {
                this.$store.state.list.success_message = "";
                this.$toast.error(response.data.message, {
                  position: "top",
                  duration: 4000,
                });
                this.$store.state.list.page_loader = false;
              }
            })
            .catch((error) => {
              this.$store.state.list.page_loader = false;
              if (error.message === "Network Error") {
                this.$toast.error(
                  "Oops there seems to be some Network issue, please try again.",
                  {
                    position: "top",
                    duration: 4000,
                  }
                );
              }
            });
        }
      } else {
        this.$store.state.list.cart_id = $cookies.get(
          window.location.hostname.substring(10, 4) + "_cart"
        );
        this.$store.state.list.page_loader = true;
        var form = new FormData();
        form.append(
          "product_id",
          this.Product_list[index].variation[0].id_product
        );
        form.append("product_parent_id", this.Product_list[index].id_product);
        form.append(
          "product_options",
          this.Product_list[index].variation[0].configrable_atribute_value
        );
        form.append("name", this.Product_list[index].name);
        form.append("sku", this.Product_list[index].variation[0].sku);
        form.append("master_sku", this.Product_list[index].sku);
        form.append("price", this.Product_list[index].price);
        form.append("qty_ordered", "1");
        form.append("final_price", this.Product_list[index].selling_price);
        form.append("store", this.$store.state.list.store);
        if (
          this.$store.state.list.cart_id != null &&
          this.$store.state.list.cart_id != ""
        ) {
          form.append("cart_id", this.$store.state.list.cart_id);
        }
        if (
          this.$store.state.list.customer_id != null &&
          this.$store.state.list.customer_id != ""
        ) {
          form.append("customer_id", this.$store.state.list.customer_id);
        }
        let config = { headers: { "Content-Type": "multipart/form-data" } };
        axios
          .post(
            this.$store.state.list.cart_api_url + "/product/add-product",
            form,
            config
          )
          .then((response) => {
            if (response.data.success === true) {
              this.$toast.success(response.data.message, {
                position: "top",
                duration: 4000,
              });
              this.$store.state.list.error_message = "";
              this.$store.state.list.page_loader = false;
              this.$store.state.list.cart_product = response.data.data.products;
              this.$store.state.list.cart_total =
                response.data.data.grand_total;
              this.$store.state.list.cart_subtotal =
                response.data.data.order_subtotal;
              this.$store.state.list.discount_code =
                response.data.data.discount_code;
              this.$store.state.list.discount_amount =
                response.data.data.discount_amount;
              this.$store.state.list.address = response.data.data.address;
              if (this.$store.state.list.address.length != 0) {
                for (
                  let i = 0;
                  i < this.$store.state.list.address.length;
                  i++
                ) {
                  if (i == 0) {
                    this.$store.state.list.address_id =
                      this.$store.state.list.address[i].id * 1;
                    this.$store.state.list.select_address_no = i;
                  }
                }
              }
              $cookies.set(
                window.location.hostname.substring(10, 4) + "_cart",
                response.data.data.cart_id
              );
              this.$store.state.list.cart_id = response.data.data.cart_id;
              this.$store.state.list.thankyou_cart = response.data.data.cart_id;
              this.$store.state.list.minicart = false;
            } else {
              this.$store.state.list.success_message = "";
              this.$toast.error(response.data.message, {
                position: "top",
                duration: 4000,
              });
              this.$store.state.list.page_loader = false;
            }
          })
          .catch((error) => {
            this.$store.state.list.page_loader = false;
            if (error.message === "Network Error") {
              this.$toast.error(
                "Oops there seems to be some Network issue, please try again.",
                {
                  position: "top",
                  duration: 4000,
                }
              );
            }
          });
      }
    },
    show_sort() {
      if (this.sort_show == true) {
        this.sort_show = false;
      } else {
        this.sort_show = true;
      }
    },
    select_sort_click(value, label) {
      this.select_sort = value;
      this.sort_show = false;
    },
    productlisthover(index) {
      this.sameslide = index;
    },
    productlisthoverout() {
      this.sameslide = 10000;
    },
    gtm_product_click: function (name, id, price, category, position) {
      this.$gtm.push({
        event: "productClick",
        action: "productClick",
        label: "Product list page",
        ecommerce: {
          click: {
            actionField: {
              list: "list",
              action: "click",
            },
            product: [
              {
                name: name,
                id: id,
                price: price,
                category: category,
                position: position + 1,
              },
            ],
          },
        },
      });
    },
    filterActive: function (item) {
      return this.mobilefilterActive === item;
    },
    close_mobile_filter: function () {
      this.filter_status = false;
    },
    toggle: function (event) {
      event.target.classList.toggle("active");
      if (screen.width < 768) {
        this.mobilefilterActive = event.target.text;
      }
    },
    filter_show: function () {
      this.filter_status = true;
    },
    add_w(id) {
      if (
        this.$store.state.list.customer_id != "" &&
        this.$store.state.list.customer_id != null
      ) {
        this.$store.state.list.page_loader = true;
        var form = new FormData();
        form.append("product_id", id);
        form.append("customer_id", this.$store.state.list.customer_id);
        form.append(
          "customer_session",
          this.$store.state.list.customer_session
        );
        axios
          .post(
            this.$store.state.list.cart_api_url + `/wishlist/add-wishlist`,
            form
          )
          .then((response) => {
            this.$store.state.list.page_loader = false;
            if (response.data.success == true) {
              //this.$store.state.list.wishlist = response.data.data.product;
              this.wishlist = response.data.data.product.split(",");
              this.$store.state.list.wishlist =
                response.data.data.product?.split(",");
              this.$store.state.list.wishlist_count =
                response.data.data.total_row;
              //this.wishlist = true;
              this.$toast.success(response.data.message, {
                position: "top",
                duration: 4000,
              });
            } else {
              this.$store.state.list.page_loader = false;
              if (response.data.data.customer_session_status == false) {
                $cookies.set(
                  window.location.hostname.substring(10, 4) + "_customer",
                  "",
                  "1y"
                );
                $cookies.set(
                  window.location.hostname.substring(10, 4) + "_cus_sess",
                  "",
                  "1y"
                );
                this.$store.state.list.customer_id = "";
                this.$store.state.list.customer_session = "";
                $cookies.set(
                  window.location.hostname.substring(10, 4) + "_cart",
                  "",
                  "1y"
                );
                $cookies.set(
                  window.location.hostname.substring(10, 4) + "_ct_sess",
                  "",
                  "1y"
                );
                this.$store.state.list.cart_id = "";
                this.$store.state.list.cart_session = "";
                this.$store.state.list.cart_product = [];
              }
            }
          });
      } else {
        this.$store.state.list.login_popup = true;
      }
    },
    remove_w(id) {
      this.$store.state.list.page_loader = true;
      var form = new FormData();
      form.append("product_id", id);
      form.append("customer_id", this.$store.state.list.customer_id);
      form.append("customer_session", this.$store.state.list.customer_session);
      axios
        .post(
          this.$store.state.list.cart_api_url + `/wishlist/remove-wishlist`,
          form
        )
        .then((response) => {
          if (response.data.success == true) {
            this.$store.state.list.page_loader = false;
            if (response.data.data.product) {
              this.wishlist = response.data.data.product.split(",");
              this.$store.state.list.wishlist =
                response.data.data.product?.split(",");
              this.$store.state.list.wishlist_count =
                response.data.data.total_row;
            } else {
              this.$store.state.list.wishlist_count = "0";
              this.wishlist = [];
            }
            this.$toast.success(response.data.message, {
              position: "top",
              duration: 4000,
            });
          } else {
            this.$store.state.list.page_loader = false;
            if (response.data.data.customer_session_status == false) {
              $cookies.set(
                window.location.hostname.substring(10, 4) + "_customer",
                "",
                "1y"
              );
              $cookies.set(
                window.location.hostname.substring(10, 4) + "_cus_sess",
                "",
                "1y"
              );
              this.$store.state.list.customer_id = "";
              this.$store.state.list.customer_session = "";
              $cookies.set(
                window.location.hostname.substring(10, 4) + "_cart",
                "",
                "1y"
              );
              $cookies.set(
                window.location.hostname.substring(10, 4) + "_ct_sess",
                "",
                "1y"
              );
              this.$store.state.list.cart_id = "";
              this.$store.state.list.cart_session = "";
              this.$store.state.list.cart_product = [];
            }
          }
        });
    },
    async getProductList(firstLoad = false) {
      // setTimeout(() =>window.scrollTo(0, 0), 0);
      this.$store.state.list.footerDescription = "";
      this.$store.state.list.topProducts = [];
      if (this.$route.name === "collections") {
        this.service = "collections";
        this.pass_url_key = this.$route.params.url_key;
      }
      this.filter_error_msg = "";
      this.$store.state.list.error_message = "";
      this.$store.state.list.success_message = "";
      this.Product_MoreLoad = false;

      try {
        const response = await axios.get(
          this.$store.state.list.api_url + `/pim/pimresponse.php/`,
          {
            params: {
              service: this.service,
              store: this.$store.state.list.store,
              url_key: this.pass_url_key,
              page: this.page,
              count: this.count,
              sort_by: this.pass_sort,
              sort_dir: this.sort_dir,
              filter: this.apifilter,
            },
          }
        );

        if (response) {
          this.blankcategory = response.data.result.title || "";
          if (response.data.response.success === 1) {
            this.schema_name = response.data.result.name;
            this.schema_desc = response.data.result.description;
            this.schema_image = response.data.result.image;
            if (response.data.result.products.length === 0) {
              this.filter_error_msg =
                "No product found, please try by removing last applied filter.";
            }

            this.foldContent = response.data.result.fold_content;
            this.$store.state.list.plpSeoContent = '';
            this.$store.state.list.plpSeoContent = response.data.result.footer_content
            this.$store.state.list.topProductsCategory =
              response.data.result.name;
            this.categoryURL = response.data.result.url_key;
            this.$store.state.list.topTenTableTitle =
              response.data.result.title;
            this.$store.state.list.topProducts = response.data.result
              .top_products
              ? response.data.result.top_products
              : [];
            this.description = response.data.result.description;
            this.$store.state.list.footerDescription =
              response.data.result.description;
            this.meta_title = response.data.result.meta_title;
            this.meta_description = response.data.result.meta_description;
            this.meta_keyword = response.data.result.meta_keyword;
            this.$store.state.list.breadCrumbArray = JSON.parse(
              response.data.result.breadcrumb
            );
            this.Product_list = [];
            this.Product_list = this.Product_list.concat(
              response.data.result.products
            );
            this.selected_sort = response.data.query.sort_by;
            try {
              this.dataForGA4Events(
                response.data.result.products,
                response.data.result.name
              );
            } catch (e) {
              console.log("helo error", e);
            }
            this.$gtm.push({
              event: "impressionSent",
              action: "Product Impression",
              label: "Product list page",
              ecommerce: {
                currencyCode: "INR",
                impressions: this.gtm_product_impressions,
              },
            });
            this.$gtm.push({ ecommerce: null }); // Clear the previous ecommerce object.
            this.$gtm.push({
              event: "view_item_list",
              ecommerce: {
                items: this.gtm_product_impressions_GA4,
              },
            });
            if (response.data.result.filters) {
              this.filters = response.data.result.filters;
              for (let i = 0; i < response.data.result.filters.length; i++) {
                if (response.data.result.filters[i].filter_lable == "Price") {
                  this.pricerange.options.min = Number(
                    response.data.result.filters[i].options[0]
                  );
                  this.pricerange.options.max = Number(
                    response.data.result.filters[i].options[1]
                  );
                  this.pricerange.value.push(
                    Number(response.data.result.filters[i].options[0])
                  );
                  this.pricerange.value.push(
                    Number(response.data.result.filters[i].options[1])
                  );
                  this.pricerange.options.code = "selling_price";
                  //this.pricerange.newValue = 'Rs.'+this.pricerange.value[0]+' to '+'Rs.'+this.pricerange.value[1];
                }
              }
            }
            // this.$store.state.list.page_loader = false
            if (response.data.result.filters.length > 0) {
              this.mobilefilterActive =
                response.data.result.filters[0].filter_lable;
            }
            if (!firstLoad) {
              this.sort = this.sort.concat(response.data.result.sort);
            }

            this.categorie_name = response.data.result.title;
            this.$store.state.list.breadcrumb = response.data.result.name;
            this.$store.state.list.breadcrumb_second.name =
              response.data.result.parent_name;
            this.$store.state.list.breadcrumb_second.url =
              response.data.result.parent_url_key;
            this.Product_count = response.data.result.count;
            this.pageSize = response.data.result.count / this.count;
            this.get_product_length = response.data.result.products.length;
            this.pageload = false;
            if (this.get_product_length >= this.count) {
              this.page++;
              this.Product_MoreLoad = true;
            } else {
              this.product_loader = false;
            }
            // this.sidebar_fixed();
          } else {
            if (response.data.parent_category) {
              this.urlkey = response.data.parent_category.url_key;
            }

            if (response.data.result) {
              this.result = response.data.result;
              this.error_message = "";
              this.$store.state.list.breadCrumbArray = JSON.parse(
                response.data.result.breadcrumb
              );
              this.meta_title = response.data.result.meta_title;
              this.meta_description = response.data.result.meta_description;
              this.meta_keyword = response.data.result.meta_keyword;
              this.product_loader = false;
              this.blankpage = true;
              this.pageload = false;
            }
            // this.$store.state.list.breadCrumbArray = []
            else {
              this.$store.state.list.error_message =
                response.data.response.error_message;
              this.$store.state.list.footerDescription =
                response.data.result.description;
              this.categorie_name = response.data.result.title
                ? response.data.result.title
                : response.data.result.name;
              this.$store.state.list.breadCrumbArray = JSON.parse(
                response.data.result.breadcrumb
              );

              this.meta_title = response.data.result.meta_title;
              this.meta_description = response.data.result.meta_description;
              this.meta_keyword = response.data.result.meta_keyword;
              this.product_loader = false;
              this.blankpage = true;
              this.pageload = false;
            }
          }
        }
      } catch (error) {
        if (error.message === "Network Error") {
          this.$store.state.list.error_message =
            "Oops there seems to be some Network issue, please try again.";
        }
      }

      // }
    },
    product_lazy_loading() {
      var loader_el = document.querySelector(".product_loader");
      var loader_position = loader_el.offsetTop;
      this.filter_error_msg = "";
      if (
        window.scrollY >= loader_position - 1000 &&
        this.Product_MoreLoad === true &&
        this.page != 1
      ) {
        this.$store.state.list.error_message = "";
        this.$store.state.list.success_message = "";
        this.Product_MoreLoad = false;
        axios
          .get(this.$store.state.list.api_url + `/pim/pimresponse.php/`, {
            params: {
              service: this.service,
              store: this.$store.state.list.store,
              url_key: this.pass_url_key,
              page: this.page,
              count: this.count,
              sort_by: this.pass_sort,
              sort_dir: this.sort_dir,
              filter: this.apifilter,
            },
          })

          .then((response) => {
            if (response.data.response.success === 1) {
              if (response.data.result.products.length === 0) {
                this.filter_error_msg =
                  "No product found, please try by removing last applied filter.";
              }
              this.meta_title = response.data.result.meta_title;
              this.meta_description = response.data.result.meta_description;
              this.meta_keyword = response.data.result.meta_keyword;
              this.$store.state.list.breadCrumbArray = JSON.parse(
                response.data.result.breadcrumb
              );
              // this.Product_list = [];
              this.Product_list = this.Product_list.concat(
                response.data.result.products
              );
              try {
                this.dataForGA4Events(response.data.result.products);
              } catch (e) {
                console.log("helo error", e);
              }
              this.$gtm.push({
                event: "impressionSent",
                action: "Product Impression",
                label: "Product list page",
                ecommerce: {
                  currencyCode: "INR",
                  impressions: this.gtm_product_impressions,
                },
              });
              this.$gtm.push({ ecommerce: null }); // Clear the previous ecommerce object.
              this.$gtm.push({
                event: "view_item_list",
                ecommerce: {
                  items: this.gtm_product_impressions_GA4,
                },
              });
              this.mobilefilterActive =
                response.data.result.filters[0].filter_lable;
              this.categorie_name = response.data.result.title;
              this.Product_count = response.data.result.count;
              this.pageSize = response.data.result.count / this.count;
              this.get_product_length = response.data.result.products.length;
              this.pageload = false;
              if (this.get_product_length >= this.count) {
                this.page++;
                this.Product_MoreLoad = true;
              } else {
                this.product_loader = false;
              }
            } else {
              this.$store.state.list.error_message =
                response.data.response.error_message;
              this.product_loader = false;
              this.blankpage = true;
              this.pageload = false;
            }
          })
          .catch((error) => {
            if (error.message === "Network Error") {
              this.$store.state.list.error_message =
                "Oops there seems to be some Network issue, please try again.";
            }
          });
      }
    },
    removefilter: function (index) {
      this.filter_error_msg = "";
      this.filtersAppied.splice(index, 1);
      this.filterview.splice(index, 1);
      this.apifilter = "";
      for (var i = 0; i < this.filtersAppied.length; i++) {
        this.apifilter += this.filtersAppied[i].replace(/\s+/g, "+");
        if (i < this.filtersAppied.length - 1) {
          this.apifilter += "|";
        }
      }
      this.$router.push({
        query: { ...this.$route.query, filter: this.filtersAppied.join("|") },
      });
      window.scrollTo(0, 0);
      this.Product_MoreLoad = true;
      this.product_loader = true;
      this.Product_list = [];
      this.page = 1;
      this.$store.state.list.error_message = "";
      this.$store.state.list.success_message = "";
      axios
        .get(this.$store.state.list.api_url + `/pim/pimresponse.php/`, {
          params: {
            service: this.service,
            store: this.$store.state.list.store,
            url_key: this.pass_url_key,
            page: this.page,
            count: this.count,
            sort_by: this.pass_sort,
            sort_dir: this.sort_dir,
            filter: this.apifilter,
          },
        })

        .then((response) => {
          if (response.data.response.success === 1) {
            if (response.data.result.products.length === 0) {
              this.filter_error_msg =
                "No product found, please try by removing last applied filter.";
            }
            this.Product_list = [];
            this.$store.state.list.breadCrumbArray = JSON.parse(
              response.data.result.breadcrumb
            );
            this.Product_list = this.Product_list.concat(
              response.data.result.products
            );
            try {
              this.dataForGA4Events(response.data.result.products);
            } catch (e) {
              console.log("helo error", e);
            }

            if (response.data.result.filters) {
              // this.filters = response.data.result.filters;
              for (let i = 0; i < response.data.result.filters.length; i++) {
                if (response.data.result.filters[i].filter_lable == "Price") {
                  this.pricerange.options.min = Number(
                    response.data.result.filters[i].options[0]
                  );
                  this.pricerange.options.max = Number(
                    response.data.result.filters[i].options[1]
                  );
                  this.pricerange.value.push(
                    Number(response.data.result.filters[i].options[0])
                  );
                  this.pricerange.value.push(
                    Number(response.data.result.filters[i].options[1])
                  );
                  this.pricerange.options.code = "selling_price";
                  //this.pricerange.newValue = 'Rs.'+this.pricerange.value[0]+' to '+'Rs.'+this.pricerange.value[1];
                }
              }
            }

            this.$gtm.push({
              event: "impressionSent",
              action: "Product Impression",
              label: "Product list page",
              ecommerce: {
                currencyCode: "INR",
                impressions: this.gtm_product_impressions,
              },
            });
            this.$gtm.push({ ecommerce: null }); // Clear the previous ecommerce object.
            this.$gtm.push({
              event: "view_item_list",
              ecommerce: {
                items: this.gtm_product_impressions_GA4,
              },
            });
            this.categorie_name = response.data.result.title;
            this.Product_count = response.data.result.count;
            this.pageSize = response.data.result.count / this.count;
            this.get_product_length = response.data.result.products.length;
            if (this.get_product_length >= this.count) {
              this.page++;
              this.Product_MoreLoad = true;
            } else {
              this.product_loader = false;
            }
          } else {
            this.filter_error_msg = response.data.response.error_message;
            this.product_loader = false;
          }
        })
        .catch((error) => {
          if (error.message === "Network Error") {
            this.$store.state.list.error_message =
              "Oops there seems to be some Network issue, please try again.";
          }
        });
    },
    removeallfilter: function () {
      this.filter_error_msg = "";
      this.filtersAppied = [];
      this.apifilter = "";
      this.filterview = [];
      window.scrollTo(0, 0);
      this.Product_MoreLoad = true;
      this.product_loader = true;
      this.Product_list = [];
      this.page = 1;
      this.$store.state.list.error_message = "";
      this.$store.state.list.success_message = "";
      this.$router.push({ query: { ...this.$route.query, filter: "" } });
      axios
        .get(this.$store.state.list.api_url + `/pim/pimresponse.php/`, {
          params: {
            service: this.service,
            store: this.$store.state.list.store,
            url_key: this.pass_url_key,
            page: this.page,
            count: this.count,
            sort_by: this.pass_sort,
            sort_dir: this.sort_dir,
            filter: this.apifilter,
          },
        })

        .then((response) => {
          if (response.data.response.success === 1) {
            if (response.data.result.products.length === 0) {
              //
              this.filter_error_msg =
                "No product found, please try by removing last applied filter.";
            }
            this.Product_list = [];
            this.$store.state.list.breadCrumbArray = JSON.parse(
              response.data.result.breadcrumb
            );
            this.Product_list = this.Product_list.concat(
              response.data.result.products
            );
            try {
              this.dataForGA4Events(response.data.result.products);
            } catch (e) {
              console.log("helo error", e);
            }

            if (response.data.result.filters) {
              // this.filters = response.data.result.filters;
              for (let i = 0; i < response.data.result.filters.length; i++) {
                if (response.data.result.filters[i].filter_lable == "Price") {
                  this.pricerange.options.min = Number(
                    response.data.result.filters[i].options[0]
                  );
                  this.pricerange.options.max = Number(
                    response.data.result.filters[i].options[1]
                  );
                  this.pricerange.value.push(
                    Number(response.data.result.filters[i].options[0])
                  );
                  this.pricerange.value.push(
                    Number(response.data.result.filters[i].options[1])
                  );
                  this.pricerange.options.code = "selling_price";
                  //this.pricerange.newValue = 'Rs.'+this.pricerange.value[0]+' to '+'Rs.'+this.pricerange.value[1];
                }
              }
            }

            this.$gtm.push({
              event: "impressionSent",
              action: "Product Impression",
              label: "Product list page",
              ecommerce: {
                currencyCode: "INR",
                impressions: this.gtm_product_impressions,
              },
            });
            this.$gtm.push({ ecommerce: null }); // Clear the previous ecommerce object.
            this.$gtm.push({
              event: "view_item_list",
              ecommerce: {
                items: this.gtm_product_impressions_GA4,
              },
            });
            this.categorie_name = response.data.result.title;
            this.Product_count = response.data.result.count;
            this.pageSize = response.data.result.count / this.count;
            this.get_product_length = response.data.result.products.length;
            if (this.get_product_length >= this.count) {
              this.page++;
              this.Product_MoreLoad = true;
            } else {
              this.product_loader = false;
            }
          } else {
            this.filter_error_msg = response.data.response.error_message;
            this.product_loader = false;
          }
        })
        .catch((error) => {
          if (error.message === "Network Error") {
            this.$store.state.list.error_message =
              "Oops there seems to be some Network issue, please try again.";
          }
        });
    },
    addfilter: function (label, element, value, value_key) {
      this.filter_error_msg = "";
      if (this.filtersAppied.indexOf(element + "~" + value) > -1) {
        for (let i = 0; i < this.filtersAppied.length; i++) {
          if (this.filtersAppied[i] === element + "~" + value) {
            this.filtersAppied.splice(i, 1);
            this.filterview.splice(i, 1);
          }
        }
      } else {
        if (label == "Price New Filter") {
          if (this.filterview.length) {
            let foundKey = 0;
            for (let i = 0; i < this.filterview.length; i++) {
              if (
                Object.keys(this.filterview[i])[0] == "Price" ||
                Object.keys(this.filterview[i])[0] == "selling_price"
              ) {
                foundKey = 1;
                this.filtersAppied[i] = element + "~" + value;
                if (Object.keys(this.filterview[i])[0] == "Price") {
                  this.filterview[i].Price = value;
                }
                if (Object.keys(this.filterview[i])[0] == "selling_price") {
                  this.filterview[i].selling_price = value;
                }
              }
            }
            if (foundKey == 0) {
              this.filtersAppied.push(element + "~" + value);
              this.filterview.push({ [label]: value });
            }
          } else {
            this.filtersAppied.push(element + "~" + value);
            this.filterview.push({ [label]: value });
          }
        } else {
          this.filtersAppied.push(element + "~" + value);
          this.filterview.push({ [label]: value });
        }
      }
      this.apifilter = "";
      for (var i = 0; i < this.filtersAppied.length; i++) {
        this.apifilter += this.filtersAppied[i].replace(/\s+/g, "+");
        if (i < this.filtersAppied.length - 1) {
          this.apifilter += "|";
        }
      }
      this.$router.push({
        query: {
          ...this.$route.query,
          filter: this.filtersAppied.join("|"),
          page: undefined,
        },
      });
      window.scrollTo(0, 0);
      this.Product_MoreLoad = true;
      this.product_loader = true;
      this.Product_list = [];
      this.page = 1;
      this.$store.state.list.error_message = "";
      this.$store.state.list.success_message = "";
      axios
        .get(this.$store.state.list.api_url + `/pim/pimresponse.php/`, {
          params: {
            service: this.service,
            store: this.$store.state.list.store,
            url_key: this.pass_url_key,
            page: this.page,
            count: this.count,
            sort_by: this.pass_sort,
            sort_dir: this.sort_dir,
            filter: this.apifilter,
          },
        })
        .then((response) => {
          if (response.data.response.success === 1) {
            if (response.data.result.products.length === 0) {
              this.filter_error_msg =
                "No product found, please try by removing last applied filter.";
            }
            this.Product_list = [];
            this.$store.state.list.breadCrumbArray = JSON.parse(
              response.data.result.breadcrumb
            );
            this.Product_list = this.Product_list.concat(
              response.data.result.products
            );
            try {
              this.dataForGA4Events(response.data.result.products);
            } catch (e) {
              console.log("helo error", e);
            }

            if (response.data.result.filters) {
              // this.filters = response.data.result.filters;
              for (let i = 0; i < response.data.result.filters.length; i++) {
                if (response.data.result.filters[i].filter_lable == "Price") {
                  this.pricerange.options.min = Number(
                    response.data.result.filters[i].options[0]
                  );
                  this.pricerange.options.max = Number(
                    response.data.result.filters[i].options[1]
                  );
                  this.pricerange.value.push(
                    Number(response.data.result.filters[i].options[0])
                  );
                  this.pricerange.value.push(
                    Number(response.data.result.filters[i].options[1])
                  );
                  this.pricerange.options.code = "selling_price";
                  //this.pricerange.newValue = 'Rs.'+this.pricerange.value[0]+' to '+'Rs.'+this.pricerange.value[1];
                }
              }
            }
            this.$gtm.push({
              event: "impressionSent",
              action: "Product Impression",
              label: "Product list page",
              ecommerce: {
                currencyCode: "INR",
                impressions: this.gtm_product_impressions,
              },
            });
            this.$gtm.push({ ecommerce: null }); // Clear the previous ecommerce object.
            this.$gtm.push({
              event: "view_item_list",
              ecommerce: {
                items: this.gtm_product_impressions_GA4,
              },
            });
            this.categorie_name = response.data.result.title;
            this.Product_count = response.data.result.count;
            this.pageSize = response.data.result.count / this.count;
            this.get_product_length = response.data.result.products.length;
            if (this.get_product_length >= this.count) {
              this.page++;
              this.Product_MoreLoad = true;
            } else {
              this.product_loader = false;
            }
          } else {
            this.filter_error_msg = response.data.response.error_message;
            this.product_loader = false;
          }
        })
        .catch((error) => {
          if (error.message === "Network Error") {
            this.$store.state.list.error_message =
              "Oops there seems to be some Network issue, please try again.";
          }
        });
    },
    isActive: function (menuItem) {
      return this.filtersAppied.indexOf(menuItem) > -1;
    },
    wishActive: function (wish) {
      return this.wishlist.indexOf(wish) > -1;
    },
    get_wish() {
      var form = new FormData();
      form.append("customer_id", this.$store.state.list.customer_id);
      form.append("customer_session", this.$store.state.list.customer_session);
      axios
        .post(
          this.$store.state.list.cart_api_url + `/wishlist/customer-wishlist`,
          form
        )
        .then((response) => {
          if (response.data.success == true) {
            //this.$store.state.list.wishpro=response.data.data.product.split(',');
            this.wishlist = response.data.data.product.split(",");
            this.$store.state.list.wishlist_count =
              response.data.data.total_row;
          }
        });
    },

    truncate(value) {
      if (value.length >= 50) {
        return value.substring(0, 50) + "...";
      } else {
        return value;
      }
    },
  },
  computed: {
    prev() {
      if (this.$route.query.page && this.$route.query.page >= 2) {
        this.currentpath =
          this.$store.state.list.base_Url +
          this.$route.path +
          "?page=" +
          (parseInt(this.$route.query.page) - 1);
        return this.currentpath;
      } else {
        if (this.$route.query.page == 1) {
          return this.$store.state.list.base_Url + this.$route.path;
        } else {
          return (this.curentpath = "");
        }
      }
    },

    next() {
      if (this.$route.query.page) {
        this.currentpath =
          this.$store.state.list.base_Url +
          this.$route.path +
          "?page=" +
          (parseInt(this.$route.query.page) + 1);
        return this.currentpath;
      } else {
        this.currentpath =
          this.$store.state.list.base_Url + this.$route.path + "?page=1";
        return this.currentpath;
      }
    },
  },

  mounted() {
    this.hideMount = false;
    // this.pageUrl = this.$route.path;
    // if (  this.$store.state.productBackPageDetail.path === this.$route.path && this.$store.state.productBackPageDetail.products.length  ) {
    //             this.$store.state.Product_list = this.$store.state.productBackPageDetail.products
    //             window.scrollTo(0, this.$store.state.productBackPageDetail.pagePosition )
    //         }
    //         else {
    //             window.scrollTo(0, 0);
    //         }
    // window.scrollTo(0, 0);
    if (
      this.$store.state.list.customer_id != "" &&
      this.$store.state.list.customer_id != null
    ) {
      this.get_wish();
    }

    // this.$store.state.list.menuActive = true,
    //     this.$store.state.list.error_message = '',
    //     this.$store.state.list.success_message = '',
    //     this.$store.state.list.page_loader = false;
    //         this.getProductList();

    $cookies.set(
      window.location.hostname.substring(10, 4) + "_last_list_path",
      this.$route.fullPath
    );
  },
  async fetch() {
    if (this.$route.query.filter) {
      this.filtersAppied = this.$route.query.filter.split("|");
      this.filterRefresh = this.$route.query.filter
        .replace(/-/g, ": ")
        .split("|");
      for (var x = 0; x < this.filterRefresh.length; x++) {
        var newKey = this.filterRefresh[x].split("~");
        this.filterview.push({ [newKey[0]]: newKey[1] });
      }
      this.apifilter = "";
      for (var i = 0; i < this.filtersAppied.length; i++) {
        this.apifilter += this.filtersAppied[i].replace(/\s+/g, "+");
        if (i < this.filtersAppied.length - 1) {
          this.apifilter += "|";
        }
      }
    }
    if (this.$route.query.sort) {
      this.isQuerySort = true;
      if (this.$route.query.sort_dir) {
        this.sort_dir = this.$route.query.sort_dir;
      }
      if (this.$route.query.sort === "price_low") {
        this.pass_sort = "selling_price";
        this.sort_dir = "asc";
      } else {
        this.pass_sort = this.$route.query.sort;
      }
      if (
        this.$route.query.sort === "selling_price" &&
        this.$route.query.sort_dir === "asc"
      ) {
        this.select_sort = "price_low";
      } else {
        this.select_sort = this.$route.query.sort;
      }
    }

    this.$store.state.list.menuActive = true;
    this.$store.state.list.error_message = "";
    this.$store.state.list.success_message = "";
    this.$store.state.list.page_loader = false;
    await this.getProductList();
  },
  // created () {
  //     if(this.$route.query.filter)
  //     {
  //         this.filtersAppied = this.$route.query.filter.split('|');
  //         this.filterRefresh = this.$route.query.filter.replace(/-/g, ': ').split('|');
  //         for( var x =0; x < this.filterRefresh.length; x++){
  //             var newKey = this.filterRefresh[x].split('~');
  //             this.filterview.push({[newKey[0]]: newKey[1]});
  //         }
  //         this.apifilter = '';
  //         for(var i=0;i< this.filtersAppied.length; i++){
  //             this.apifilter += this.filtersAppied[i].replace(/\s+/g,"+");
  //         if(i < (this.filtersAppied.length-1) ){
  //             this.apifilter += '|';
  //         }
  //         }
  //     }
  //     if(this.$route.query.sort)
  //     {

  //         this.isQuerySort = true
  //         if(this.$route.query.sort_dir)
  //         {

  //             this.sort_dir = this.$route.query.sort_dir;
  //         }
  //         if(this.$route.query.sort === 'price_low')
  //         {
  //             this.pass_sort = 'selling_price';
  //             this.sort_dir = 'asc';
  //         }
  //         else
  //         {
  //             this.pass_sort = this.$route.query.sort;
  //         }
  //          if(this.$route.query.sort === 'selling_price' && this.$route.query.sort_dir === 'asc')
  //         {
  //            this.select_sort = 'price_low'
  //         } else {
  //              this.select_sort = this.$route.query.sort;
  //     }
  //     }

  //     this.$store.state.list.menuActive = true;
  //     this.$store.state.list.error_message = '';
  //     this.$store.state.list.success_message = '';
  //     this.$store.state.list.page_loader = false;
  //     this.getProductList(true);

  // },
  beforeMount() {
    if (screen.width <= 767) {
      this.filter_status = false;
      this.mobile_view = true;
    }
    var divsToHide = document.getElementsByClassName("dropdown");
    for (var i = 0; i < divsToHide.length; i++) {
      divsToHide[i].style.pointerEvents = "none";
    }
    setTimeout(function () {
      for (var i = 0; i < divsToHide.length; i++) {
        divsToHide[i].style.pointerEvents = "auto";
      }
    }, 1000);

    window.addEventListener("scroll", this.product_lazy_loading);
    window.addEventListener("scroll", this.pageQuery);
    let scrollPos = 0;
    let top_set_offset = 0;
    window.addEventListener("scroll", function sidebarfixed() {
      if (window.innerWidth > 767) {
        try {
          var offsetHeight =
            document.getElementById("sidebar-inner").offsetHeight;
        } catch (error) {
          return;
        }
        let window_height = window.innerHeight;
        let mainoffsetHeight =
          document.getElementById("sidebar-main").offsetHeight;
        let offsetTop = document.getElementById("sidebar-inner").offsetTop;
        let pageoffsetTop = window.pageYOffset;
        let topscroll = document.documentElement.scrollTop;
        if (document.body.getBoundingClientRect().top > scrollPos) {
          if (window_height - 115 < offsetHeight) {
            if (top_set_offset + 135 < topscroll) {
              document
                .getElementById("sidebar-inner")
                .classList.remove("bottom");
              document.getElementById("sidebar-inner").classList.remove("top");
              document.getElementById("sidebar-inner").style.position =
                "relative";
              document.getElementById("sidebar-inner").style.top =
                offsetTop + "px";
            } else {
              document.getElementById("sidebar-inner").classList.add("top");
              document
                .getElementById("sidebar-inner")
                .classList.remove("bottom");
              document.getElementById("sidebar-inner").style.position =
                "sticky";
              document.getElementById("sidebar-inner").style.top = "115px";
            }
          }
        } else {
          if (window_height - 115 < offsetHeight) {
            top_set_offset = document.getElementById("sidebar-inner").offsetTop;
            if (topscroll < offsetHeight + offsetTop + 115) {
              document.getElementById("sidebar-inner").classList.add("bottom");
              document.getElementById("sidebar-inner").classList.remove("top");
              document.getElementById("sidebar-inner").style.position =
                "sticky";
              document.getElementById("sidebar-inner").style.top = "auto";
              document.getElementById("sidebar-inner").style.bottom = "20px";
            }
          } else {
            document.getElementById("sidebar-inner").classList.add("top");
            document.getElementById("sidebar-inner").classList.remove("bottom");
            document.getElementById("sidebar-inner").style.position = "sticky";
            document.getElementById("sidebar-inner").style.top = "115px";
          }
        }
        scrollPos = document.body.getBoundingClientRect().top;
      }
    });
  },
  watch: {
    select_sort: {
      handler: function () {
        window.scrollTo(0, 0);
        this.filter_error_msg = "";
        this.Product_MoreLoad = true;
        if (this.select_sort === "price_low") {
          this.pass_sort = "selling_price";
          this.sort_dir = "asc";
          this.$router.push({
            query: {
              ...this.$route.query,
              sort: "selling_price",
              sort_dir: this.sort_dir,
            },
          });
        } else {
          this.pass_sort = this.select_sort;

          if (this.isQuerySort) {
            this.sort_dir = this.$route.query.sort_dir;
            this.isQuerySort = false;
            return;
          } else {
            this.sort_dir = "desc";
          }
          this.$router.push({
            query: {
              ...this.$route.query,
              sort: this.select_sort,
              sort_dir: this.sort_dir,
            },
          });
        }
        this.product_loader = true;
        this.$store.state.list.error_message = "";
        this.$store.state.list.success_message = "";
        axios
          .get(this.$store.state.list.api_url + `/pim/pimresponse.php/`, {
            params: {
              service: this.service,
              store: this.$store.state.list.store,
              url_key: this.pass_url_key,
              page: this.page,
              count: this.count,
              sort_by: this.pass_sort,
              sort_dir: this.sort_dir,
              filter: this.apifilter,
            },
          })

          .then((response) => {
            this.Product_list = [];
            this.page = 1;
            if (response.data.response.success === 1) {
              if (response.data.result.products.length === 0) {
                this.filter_error_msg = "Products Not Found";
              }
              this.Product_list = [];
              this.Product_list = this.Product_list.concat(
                response.data.result.products
              );
              this.selected_sort = response.data.query.sort_by;
              try {
                this.dataForGA4Events(response.data.result.products);
              } catch (e) {
                console.log("helo error", e);
              }

              if (response.data.result.filters) {
                // this.filters = response.data.result.filters;
                for (let i = 0; i < response.data.result.filters.length; i++) {
                  if (response.data.result.filters[i].filter_lable == "Price") {
                    this.pricerange.options.min = Number(
                      response.data.result.filters[i].options[0]
                    );
                    this.pricerange.options.max = Number(
                      response.data.result.filters[i].options[1]
                    );
                    this.pricerange.value.push(
                      Number(response.data.result.filters[i].options[0])
                    );
                    this.pricerange.value.push(
                      Number(response.data.result.filters[i].options[1])
                    );
                    this.pricerange.options.code = "selling_price";
                    //this.pricerange.newValue = 'Rs.'+this.pricerange.value[0]+' to '+'Rs.'+this.pricerange.value[1];
                  }
                }
              }
              this.$gtm.push({
                event: "impressionSent",
                action: "Product Impression",
                label: "Product list page",
                ecommerce: {
                  currencyCode: "INR",
                  impressions: this.gtm_product_impressions,
                },
              });
              this.$gtm.push({ ecommerce: null }); // Clear the previous ecommerce object.
              this.$gtm.push({
                event: "view_item_list",
                ecommerce: {
                  items: this.gtm_product_impressions_GA4,
                },
              });
              this.categorie_name = response.data.result.title;
              this.Product_count = response.data.result.count;
              this.pageSize = response.data.result.count / this.count;
              this.get_product_length = response.data.result.products.length;
              if (this.get_product_length >= this.count) {
                this.page++;
                this.Product_MoreLoad = true;
              } else {
                this.product_loader = false;
              }
            } else {
              this.$store.state.list.error_message =
                response.data.response.error_message;
              this.product_loader = false;
            }
          })
          .catch((error) => {
            this.product_loader = false;
            if (error.message === "Network Error") {
              this.$store.state.list.error_message =
                "Oops there seems to be some Network issue, please try again.";
            }
          });
      },
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.product_lazy_loading);
    window.removeEventListener("scroll", this.sidebarfixed);
    window.removeEventListener("scroll", this.pageQuery);
  },
};
